export enum AppTheme {
  DEFAULT = 'default',
  AGKAMED = 'agkamed',
  AGKAMED_TEST = 'agkamed-test',
  AKK = 'akk',
  AMEOS = 'ameos',
  BG_KLINIKEN = 'bg-kliniken',
  DIAKOVERE = 'diakovere',
  EKK = 'ekk',
  EKK_TEST = 'ekk-test',
  JDS = 'jds',
  MEDIIO = 'mediio',
  SANA = 'sana',
  UKE = 'uke',
  XMEDIQ = 'xmediq',
}
