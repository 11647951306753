import { ComponentType } from '@angular/cdk/portal';
import { EmbeddedViewRef, Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';

export enum ToastDuration {
  Infinite = 0,
  Short = 2000,
  Long = 3500,
}

const MP_TOAST_CLASSNAME = 'mp-toast';

export enum ToastUrgency {
  Default = 'default',
  Success = 'success',
  Info = 'info',
  Warning = 'warning',
  Danger = 'danger',
}

@Injectable({ providedIn: 'root' })
export class NotificationService {
  constructor(private readonly snackbar: MatSnackBar) {}

  toast(message: string, action = '', config?: MatSnackBarConfig): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbar.open(message, action, config);
  }

  toastCustom(
    message: string,
    duration: ToastDuration | number = ToastDuration.Short,
    urgency: ToastUrgency = ToastUrgency.Default,
    action = '',
  ): MatSnackBarRef<SimpleSnackBar> {
    return this.snackbar.open(message, action, {
      duration,
      panelClass: [MP_TOAST_CLASSNAME, `${MP_TOAST_CLASSNAME}--${urgency}`],
    });
  }

  toastDanger(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.toastCustom(message, ToastDuration.Long, ToastUrgency.Warning);
  }

  toastDefault(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.toastCustom(message);
  }

  toastSuccess(message: string): MatSnackBarRef<SimpleSnackBar> {
    return this.toastCustom(message, ToastDuration.Short, ToastUrgency.Success);
  }

  toastFromTemplate(
    template: TemplateRef<unknown>,
    data?: unknown,
    duration: ToastDuration | number = ToastDuration.Short,
  ): MatSnackBarRef<EmbeddedViewRef<unknown>> {
    return this.snackbar.openFromTemplate(template, {
      data,
      duration,
      panelClass: [MP_TOAST_CLASSNAME],
    });
  }

  toastFromComponent<T>(
    component: ComponentType<T>,
    data?: unknown,
    duration: ToastDuration | number = ToastDuration.Short,
  ): MatSnackBarRef<T> {
    return this.snackbar.openFromComponent(component, {
      data,
      duration,
    });
  }
}
