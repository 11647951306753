import { Directive, ElementRef, Input } from '@angular/core';
import { MatLegacyLabel as MatLabel } from '@angular/material/legacy-form-field';

@Directive({
  selector: 'mat-label[mpValidationLabel]',
  standalone: true,
  providers: [
    {
      provide: MatLabel,
      useExisting: ValidationLabelDirective,
    },
  ],
})
export class ValidationLabelDirective extends MatLabel {
  constructor(private readonly el: ElementRef<HTMLElement>) {
    super();
  }

  @Input()
  mpValidationLabel?: string;

  /**
   * Label für die Validierungsmeldung.
   */
  public get label(): string {
    return this.mpValidationLabel || this.el.nativeElement?.innerHTML || '';
  }
}
