import { Directive, DoCheck, ElementRef, Inject, Input, Optional } from '@angular/core';
import { type ValidationErrors } from '@angular/forms';
import { MatLegacyFormField as MatFormField } from '@angular/material/legacy-form-field';

import { ValidationLabelDirective } from './validation-label.directive';

function tagged(strings: TemplateStringsArray, ...keys: string[]) {
  return (values: Record<string, unknown>): string => {
    let i = 0;
    const result = [strings[i++]];

    for (const key of keys) {
      result.push(`${values[key]}`, strings[i++]);
    }

    return result.join('');
  };
}

const defaultMessages: Record<string, (values: Record<string, unknown>) => string> = {
  server: tagged`${'msg'}`,
  required: tagged`${'_'} muss angegeben werden.`,
  min: tagged`${'_'} muss größer oder gleich ${'min'} sein.`,
  max: tagged`${'_'} muss kleiner oder gleich ${'max'} sein.`,
  minlength: tagged`${'_'} muss mindestens ${'requiredLength'} Zeichen lang sein.`,
  maxlength: tagged`${'_'} darf maximal ${'requiredLength'} Zeichen lang sein.`,
  email: tagged`${'_'} ist keine gültige E-Mail-Adresse.`,
  url: tagged`${'_'} ist keine gültige URL.`,
};

/**
 * Ermittelt den ersten Validierungsfehler eines Controls und gibt es als Inhalt des Elements zurück.
 */
@Directive({
  selector: '[mpValidationError]',
  standalone: true,
})
export class ValidationErrorDirective implements DoCheck {
  constructor(
    private readonly el: ElementRef<HTMLElement>,
    @Inject(MatFormField) @Optional() private readonly formField: MatFormField | null,
  ) {}

  /**
   * Die Fehlermeldungs-Templates.
   */
  @Input()
  public messages: Record<string, string | ((values: object) => string)> = {};

  ngDoCheck(): void {
    this.el.nativeElement.innerHTML = this.getFirstError(this.formField?._control?.ngControl?.errors) || '';
  }

  private getFirstError(validationErrors?: ValidationErrors | null): string | null {
    if (validationErrors) {
      // Server zuerst (manueller Fehler)
      let err = validationErrors['server'];

      if (err) {
        return this.formatMessage('server', err);
      }

      // Dann required
      err = validationErrors['required'];

      if (err) {
        return this.formatMessage('required', err);
      }

      // Ansonsten den erstbesten nehmen
      for (const [name, err] of Object.entries(validationErrors)) {
        if (err) {
          return this.formatMessage(name, err);
        }
      }
    }

    return null;
  }

  private formatMessage(key: string, err: object): string {
    const msg = this.messages[key] ?? defaultMessages[key] ?? key;

    if (typeof msg === 'function') {
      return msg({
        ...err,
        _: this.getLabel() || 'Der Wert',
      });
    }

    return msg || `${key}`;
  }

  private getLabel(): string {
    const labelCtrl = this.formField?._labelChildNonStatic || this.formField?._labelChildStatic;

    if (labelCtrl && labelCtrl instanceof ValidationLabelDirective) {
      return labelCtrl.label;
    }

    return '';
  }
}
