import { type FormGroup } from '@angular/forms';

export function setServerValidationErrors(formGroup: FormGroup, errors: Record<string, string[]>): void {
  for (const [field, messages] of Object.entries(errors)) {
    const ctrl = formGroup.get(field);

    if (ctrl) {
      ctrl.setErrors({
        server: {
          msg: messages[0],
        },
      });
    }
  }
}
